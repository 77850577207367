import React, { ReactNode } from 'react';
import clsx from 'clsx';
import style from './index.module.css'; // eslint-disable-line

const TextSizes = ['XS', 'S', 'M', 'L'] as const;
type TextSize = (typeof TextSizes)[number];

type TextProps = {
  subtle?: boolean;
  size?: TextSize;
  className?: string;
  block?: boolean;
  bold?: boolean;
  semibold?: boolean;
  children: ReactNode;
};

const Text = React.forwardRef<HTMLDivElement, TextProps>(function Text(
  { children, size, subtle = false, className, block, bold = false, semibold = false },
  ref,
) {
  const Component = block ? 'div' : 'span';
  return (
    <Component
      ref={ref}
      className={clsx(
        {
          [style.subtle]: subtle,
          [style.bold]: bold,
          [style.semibold]: semibold,
        },
        size && style[size],
        className,
      )}
    >
      {children}
    </Component>
  );
});

export default Text;
