import { useMemo } from 'react';
import { PartnerApplication } from '../components/lib/Account';
import { Theme } from '../components/Theme';
import { getPartnerApplicationFromPathname } from './routing/Routes';

export type FeaturedReport = {
  target: {
    workspaceName?: string;
    reportName: string;
    pageDisplayName: string;
  };
  title: string;
  description: string;
  iconName?: string;
};

type Settings = {
  theme: Theme;
  documentTitle: string;
  featuredReports: FeaturedReport[];
  termsAndConditionsUrl?: string;
};

const PartnerSettings: Readonly<Record<PartnerApplication, Settings>> = {
  [PartnerApplication.Dot]: {
    documentTitle: 'Dot at Crisp',
    theme: Theme.Dot,
    featuredReports: [
      {
        title: 'YoY Sales',
        description: 'Compare sales to last year to discover what drives growth',
        iconName: 'YoYSales',
        target: {
          reportName: 'Performance Insights',
          pageDisplayName: 'Supplier Trends',
        },
      },
      {
        title: 'Distribution Center Inventory',
        description: 'Monitor on-hand DC inventory to proactively replenish supply',
        iconName: 'DistributionInventory',
        target: {
          reportName: 'Inventory Flow Analysis',
          pageDisplayName: 'DC Details',
        },
      },
      {
        title: 'Distribution expansion',
        description: 'Find top-performing stores in your category to optimize your distribution',
        iconName: 'DistributionExpansion',
        target: {
          reportName: 'Distribution Analysis',
          pageDisplayName: 'Expansion Summary',
        },
      },
    ],
    termsAndConditionsUrl: 'https://www.dotfoods.com/Crisp-at-Dot/',
  },
};

const DefaultSettings: Settings = PartnerSettings[PartnerApplication.Dot];

export default PartnerSettings;

export const usePartnerSettings = () => {
  return useMemo(() => {
    const partnerApplication = getPartnerApplicationFromPathname(window.location.pathname);
    return (partnerApplication && PartnerSettings[partnerApplication]) || DefaultSettings;
  }, []);
};
