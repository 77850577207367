import React, { useEffect, useMemo, useState } from 'react';
import { Maybe } from '@tellurian/ts-utils';
import clsx from 'clsx';
import SectionTitle from '../SectionTitle';
import Modal, { ModalProps } from './index';
import style from './ModalWithSections.module.css';

export type ModalSection<T extends string = string> = {
  id: T;
  title: string;
};

type GroupedModalSection<T extends string = string> = {
  title: string;
  sections: ModalSection<T>[];
};

export type RenderSectionComponent<T extends string = string> = React.FC<ModalSection<T>>;

const getSelectedSectionId = <T extends string = string>(
  selectedSectionId: Maybe<T>,
  sections: ModalSection<T>[],
) => {
  if (selectedSectionId) {
    return selectedSectionId;
  }
  if (sections.length > 0) {
    return sections[0].id;
  }

  return undefined;
};

type ModalWithSectionsProps<T extends string = string> = Omit<ModalProps, 'children'> & {
  RenderSection: RenderSectionComponent<T>;
  sections: GroupedModalSection<T>[] | ModalSection<T>[];
  selectedSectionId?: Maybe<T>;
};

function ModalWithSections<T extends string = string>({
  RenderSection,
  sections,
  selectedSectionId: upstreamSelectedSectionId,
  ...modalProps
}: ModalWithSectionsProps<T>) {
  // Easier to work with a single structure downstream
  const canonicalSections: GroupedModalSection<T>[] = useMemo(() => {
    if (sections.length > 0) {
      if ('sections' in sections[0]) {
        return sections as GroupedModalSection<T>[];
      } else {
        return [{ title: '', sections: sections as ModalSection<T>[] }];
      }
    }

    return [];
  }, [sections]);

  const allSections = useMemo(
    () => canonicalSections.flatMap(group => group.sections),
    [canonicalSections],
  );

  const [selectedSectionId, setSelectedSectionId] = useState<Maybe<T>>(() =>
    getSelectedSectionId(upstreamSelectedSectionId, allSections),
  );

  useEffect(() => {
    setSelectedSectionId(getSelectedSectionId(upstreamSelectedSectionId, allSections));
  }, [upstreamSelectedSectionId, allSections]);

  const currentSection = useMemo(
    () =>
      selectedSectionId ? allSections.find(section => section.id === selectedSectionId) : undefined,
    [allSections, selectedSectionId],
  );

  return (
    <Modal {...modalProps}>
      <div className={style.container}>
        <div className={style.sections}>
          {canonicalSections.map(group => (
            <div key={group.title}>
              {group.title && <SectionTitle className="mhs">{group.title}</SectionTitle>}
              <ul>
                {group.sections.map(section => (
                  <li key={section.id}>
                    <button
                      className={clsx(style.selectSection, {
                        [style.active]: section.id === selectedSectionId,
                      })}
                      onClick={() => setSelectedSectionId(section.id)}
                    >
                      {section.title}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
        <div>{currentSection && <RenderSection {...currentSection} />}</div>
      </div>
    </Modal>
  );
}

export default ModalWithSections;
