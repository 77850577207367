import React, { useState } from 'react';
import { ThemeOption, useUserPreferencesContext } from '../UserPreferencesProvider';
import useIsGlobalAdmin from '../../../../../utils/useIsGlobalAdmin';
import Title from '../../../components/Title';
import RadioGroupField from '../../../components/RadioGroupField';
import CheckboxField from '../../../components/CheckboxField';
import Notification from '../../../components/Notification';
import { NotificationType } from '../../../components/lib/notifications/lib';

const FullSupportThemes: ThemeOption[] = [
  'Crisp Lettuce',
  'Advantage',
  'High Impact',
  'Dot',
  'Instacart',
];
const PartialSupportThemes: ThemeOption[] = ['UNFI', 'Rema'];

const ThemePreferences: React.FC = () => {
  const { setTheme, updatePreferences } = useUserPreferencesContext();
  const { preferences } = useUserPreferencesContext();
  const isGlobalAdmin = useIsGlobalAdmin();
  const [showApplicationThemes, setShowApplicationThemes] = useState(false);

  return (
    <>
      <Title>Theme</Title>
      <RadioGroupField
        radioItems={FullSupportThemes}
        value={preferences.general.theme}
        onChange={setTheme}
      />
      <hr />
      <CheckboxField
        label="Override account bound theme"
        description="If checked, the preferred theme will take precedence over the theme associated with a particular account."
        checked={preferences.general.overrideAccountTheme}
        onChange={isChecked => updatePreferences({ general: { overrideAccountTheme: isChecked } })}
      />
      {isGlobalAdmin && (
        <CheckboxField
          label="Show application themes"
          checked={showApplicationThemes}
          onChange={setShowApplicationThemes}
        />
      )}
      {showApplicationThemes && (
        <>
          <Title size="M">Application themes</Title>
          <RadioGroupField
            radioItems={PartialSupportThemes}
            value={preferences.general.theme}
            onChange={setTheme}
          />
          <Notification
            notificationType={NotificationType.Info}
            content={
              <div>
                Application themes define styles for the subset of features pertaining to the
                respective application. These are not applicable to our entire suite of components
                and are primarily present to help development.
              </div>
            }
          />
        </>
      )}
    </>
  );
};

export default ThemePreferences;
