import React, { useMemo } from 'react';
import { ReactComponent as LogOutIcon } from '../../../../assets/icons/lettuce/logout.svg';
import { ReactComponent as DocumentIcon } from '../../../../assets/icons/lettuce/document.svg';
import UserDetails from '../../components/lib/navigation/UserDetails';
import { ItemSeparator } from '../../components/Menu/RenderMenuItem';
import Menu from '../../components/Menu';
import { usePartnerSettings } from '../PartnerSettings';

const handleLogout = () => {
  window.location.href = '/p/logout';
};

const handleExternalLink = (url?: string) => {
  if (url) window.open(url, '_blank');
};

const UserSettingsMenu: React.FC = () => {
  const partnerSettings = usePartnerSettings();
  const items = useMemo(() => {
    const menuItems = [
      {
        label: 'Log out',
        Icon: LogOutIcon,
        onToggle: handleLogout,
      },
    ];

    if (partnerSettings.termsAndConditionsUrl) {
      menuItems.push({
        label: 'Terms and Conditions',
        Icon: DocumentIcon,
        onToggle: () => {
          handleExternalLink(partnerSettings.termsAndConditionsUrl);
        },
      });
    }

    return menuItems;
  }, [partnerSettings]);
  return (
    <>
      <UserDetails />
      <div className="mhs mbs">
        <ItemSeparator />
        <Menu aria-label="Settings menu" items={items} />
      </div>
    </>
  );
};

export default UserSettingsMenu;
