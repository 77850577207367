import React from 'react';
import Title from '../../../components/Title';
import CheckboxField from '../../../components/CheckboxField';
import { useUserPreferencesContext } from '../UserPreferencesProvider';
import useIsGlobalAdmin from '../../../../../utils/useIsGlobalAdmin';

const PowerBiPreferences: React.FC = () => {
  const { preferences, updatePreferences } = useUserPreferencesContext();
  const powerBiPreferences = preferences.labs.powerBi;
  const isGlobalAdmin = useIsGlobalAdmin();
  return (
    <>
      <Title size="M">PowerBI</Title>
      <CheckboxField
        label="Enable PowerBI UX v2"
        checked={powerBiPreferences.ux2}
        onChange={isChecked =>
          updatePreferences({
            labs: { powerBi: { ...powerBiPreferences, ux2: isChecked } },
          })
        }
      />
      {isGlobalAdmin && (
        <>
          <CheckboxField
            label="Enable UX v2 PowerBI report theme"
            checked={powerBiPreferences.ux2ReportThemeEnabled}
            onChange={isChecked =>
              updatePreferences({
                labs: { powerBi: { ...powerBiPreferences, ux2ReportThemeEnabled: isChecked } },
              })
            }
            disabled={!powerBiPreferences.ux2}
          />
          <CheckboxField
            label="Hide report slicers in UX v2"
            checked={powerBiPreferences.ux2HideSlicers}
            onChange={isChecked =>
              updatePreferences({
                labs: { powerBi: { ...powerBiPreferences, ux2HideSlicers: isChecked } },
              })
            }
            disabled={!powerBiPreferences.ux2}
          />
        </>
      )}
    </>
  );
};

export default PowerBiPreferences;
