import React from 'react';
import clsx from 'clsx';
import { FCC } from '../../../../utils/types';
import style from './index.module.css';

type SectionTitleProps = {
  className?: string;
};

const SectionTitle: FCC<SectionTitleProps> = ({ className, children }) => {
  return <div className={clsx(style.sectionTitle, className)}>{children}</div>;
};

export default SectionTitle;
