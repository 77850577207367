import React from 'react';
import { RouteComponentProps } from 'react-router';
import ConnectorsTemplate from '../../../../pageTemplates/AccountTemplate/ConnectorsTemplate';
import InboundDataStatus from '../../../InboundDataStatus';
import {
  ByReportDateFilter,
  ByRunDateFilter,
} from '../../../InboundDataStatus/ConnectorDetail/lib';
import { InboundDataStatusTab } from '../routing/Tabs';
import { lazyWithRetry } from '../../../lib';

const RenderInboundDataStats = lazyWithRetry<typeof InboundDataStatus>(
  () => import(/* webpackChunkName: "inboundDataStatus" */ '../../crisp/InboundDataStatus'),
);

type MatchParams = {
  accountId: string;
  connectorConfigurationSlug?: string;
  tab?: InboundDataStatusTab;
  filter?: ByReportDateFilter | ByRunDateFilter;
};

const InboundDataStatusPage: React.FC<RouteComponentProps<MatchParams>> = ({
  match: { params },
}) => {
  return (
    <ConnectorsTemplate>
      <RenderInboundDataStats {...params} />
    </ConnectorsTemplate>
  );
};

export default InboundDataStatusPage;
