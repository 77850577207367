import React, { useMemo } from 'react';
import { ReactComponent as UsersIcon } from '../../../../assets/icons/lettuce/users.svg';
import { ReactComponent as LogOutIcon } from '../../../../assets/icons/lettuce/logout.svg';
import { ReactComponent as SettingsIcon } from '../../../../assets/icons/lettuce/settings.svg';
import { Feature } from '../../../../utils/features';
import Menu from '../../components/Menu';
import { MenuItemSpec } from '../../components/Menu/lib';
import { ItemSeparator } from '../../components/Menu/RenderMenuItem';
import { logOut } from '../../../security/lib';
import { path } from '../routing/lib';
import UserDetails from '../../components/lib/navigation/UserDetails';
import { useUserPreferencesContext } from '../UserPreferences/UserPreferencesProvider';
import { useHasUserPreferenceOptions } from '../UserPreferences/editor/usePreferenceSections';
import { usePageSpecificNavigationContext } from './PageSpecificNavigationContext';

const handleLogout = () => {
  logOut().then(() => (window.location.href = '/'));
};

const useGetMenuItems = (isAccountAdmin: boolean, accountId?: string): MenuItemSpec[] => {
  const {
    items: { logOutRetailAnalytics },
  } = usePageSpecificNavigationContext();
  const hasUserPreferenceOptions = useHasUserPreferenceOptions();
  const { toggleEditor } = useUserPreferencesContext();

  return useMemo(
    () =>
      [
        accountId &&
          isAccountAdmin && {
            label: 'Manage users',
            featureId: Feature.NavMenuManageUsers,
            path: path('ManageAccountMembers')({ accountId }),
            Icon: UsersIcon,
          },
        // Render this to the user as settings, use "preferences" internally to distinguish from
        // other more generic application settings.
        hasUserPreferenceOptions && {
          label: 'Settings',
          featureId: Feature.NavMenuPreferences,
          onToggle: toggleEditor,
          Icon: SettingsIcon,
        },
        logOutRetailAnalytics && {
          label: 'Log out Retail Analytics',
          Icon: LogOutIcon,
          onToggle: logOutRetailAnalytics,
        },
        {
          label: 'Log out',
          Icon: LogOutIcon,
          onToggle: handleLogout,
        },
      ].filter(Boolean) as MenuItemSpec[],
    [accountId, isAccountAdmin, logOutRetailAnalytics, hasUserPreferenceOptions, toggleEditor],
  );
};

export type UserSettingsMenuProps = {
  accountId?: string;
  isAccountAdmin: boolean;
};

const UserSettingsMenu: React.FC<UserSettingsMenuProps> = React.memo(function UserSettingsMenu({
  accountId,
  isAccountAdmin,
}) {
  const items = useGetMenuItems(isAccountAdmin, accountId);
  return (
    <>
      <UserDetails />
      <div className="mhs mbs">
        <ItemSeparator />
        <Menu aria-label="Settings menu" items={items} />
      </div>
    </>
  );
});

export default UserSettingsMenu;
