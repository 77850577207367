import React, { useCallback, useEffect, useState } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { Maybe } from '@tellurian/ts-utils';
import { useSaveAuthProviderUserTokenMutation } from '../../../../generated/graphql';
import logger from '../../../../services/logger';
import { FCC } from '../../../../utils/types';
import { useAuthenticationContext } from '../../../security/AuthenticationContext';
import Spinner from '../../components/Spinner';
import NotificationCard from '../../components/NotificationCard';
import PageContainer from '../../../../pageTemplates/PageContainer';
import ContactUs from '../../../Links/ContactUs';
import Flex from '../../components/Flex';
import { NotificationState, NotificationType } from '../../components/lib/notifications/lib';
import { usePartnerAuthContext } from './PartnerAuthContext';

export const PartnerUserTokenHelper: FCC = ({ children }) => {
  const { oktaAuth } = useOktaAuth();
  const { authProvider } = usePartnerAuthContext();

  const { firebaseUser } = useAuthenticationContext();
  const [saveAuthProviderUserToken, { error: saveTokenError }] =
    useSaveAuthProviderUserTokenMutation();

  const [tokenSaved, setTokenSaved] = useState(false);

  const saveUserToken = useCallback(
    (accessToken: Maybe<string>, idToken: Maybe<string>, nonce: Maybe<string>) => {
      if (accessToken && idToken && authProvider && nonce && firebaseUser) {
        saveAuthProviderUserToken({
          variables: {
            input: {
              providerId: authProvider?.providerId,
              accessToken,
              idToken,
              nonce: nonce,
            },
          },
        })
          .then(() => {
            setTokenSaved(true);
          })
          .catch(saveTokenError => {
            logger.error('SaveAuthProviderUserToken error:', saveTokenError);
          });
      }
    },
    [authProvider, firebaseUser, saveAuthProviderUserToken],
  );

  useEffect(() => {
    oktaAuth.tokenManager.on('renewed', () => {
      oktaAuth.tokenManager
        .getTokens()
        .then(tokens => {
          const accessToken = tokens.accessToken?.accessToken;
          const idToken = tokens.idToken?.idToken;
          if (accessToken && idToken && authProvider && firebaseUser) {
            saveUserToken(accessToken, idToken, tokens.idToken?.claims.nonce);
          }
        })
        .catch(saveTokenError => {
          logger.error('SaveAuthProviderUserToken error:', saveTokenError);
        });
    });
  }, [
    oktaAuth,
    authProvider,
    saveAuthProviderUserToken,
    saveTokenError,
    saveUserToken,
    firebaseUser,
  ]);

  const { accessToken, idToken, oktaUser } = usePartnerAuthContext();
  useEffect(() => {
    if (accessToken && !tokenSaved && authProvider && oktaUser) {
      saveUserToken(accessToken, idToken, oktaUser?.nonce);
    }
  }, [accessToken, idToken, authProvider, saveUserToken, tokenSaved, oktaUser?.nonce, oktaUser]);

  if (saveTokenError) {
    return (
      <PageContainer>
        <Flex spaceBetween column>
          <NotificationCard
            state={NotificationState.Showing}
            notificationType={NotificationType.Failure}
            content={
              <>
                An error occurred whilst authenticating user. Please try again and if the problem
                persists, contact customer support.
                <ContactUs
                  subject={'Partner Authorization Error'}
                  body={`Partner Authorization Error Message: ${saveTokenError.message}`}
                />
              </>
            }
          />
        </Flex>
      </PageContainer>
    );
  }

  if (!tokenSaved && !saveTokenError) {
    return <Spinner />;
  }

  return children;
};
