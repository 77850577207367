import { useMemo } from 'react';
import useIsGlobalAdmin from '../../../../../utils/useIsGlobalAdmin';
import useIsFeatureFlagEnabled from '../../../common/featureFlags/useIsFeatureFlagEnabled';
import { KnownFlags } from '../../../common/featureFlags/KnownFlags';
import { ModalSection } from '../../../components/Modal/ModalWithSections';

export type PreferencesSectionId = 'theme' | 'powerBi';

const usePreferenceSections = (): ModalSection<PreferencesSectionId>[] => {
  const isGlobalAdmin = useIsGlobalAdmin();
  const isPowerBiEnabled = useIsFeatureFlagEnabled(KnownFlags.PowerBiEmbeddedReports);
  const isCRA2Enabled = useIsFeatureFlagEnabled(KnownFlags.CRA2);

  return useMemo(
    () =>
      [
        isGlobalAdmin && {
          id: 'theme',
          title: 'Theme',
        },
        ((isPowerBiEnabled && isCRA2Enabled) || isGlobalAdmin) && {
          id: 'powerBi',
          title: 'Power BI',
        },
      ].filter(Boolean) as ModalSection<PreferencesSectionId>[],
    [isGlobalAdmin, isPowerBiEnabled, isCRA2Enabled],
  );
};

export default usePreferenceSections;

export const useHasUserPreferenceOptions = () => usePreferenceSections().length > 0;
